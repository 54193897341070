import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { css, useTheme } from '@emotion/react'

const getVariantStyles = (theme, variant) => {
  const { colors } = theme

  switch (variant) {
    case 'fill':
      return css`
        color: ${colors.black[0]};
        text-decoration: underline;
        padding: 0 0.25rem;

        :hover {
          color: ${colors.white};
          text-decoration: none;
          background-color: ${colors.black[0]};
        }
      `
    case 'nav':
      return css`
        color: ${colors.black[0]};

        :hover {
          text-decoration: underline;
        }
      `
    default:
      return
  }
}

const Link = ({
  children,
  activeClassName,
  variant,
  partiallyActive,
  to,
  ariaLabel,
  ...rest
}) => {
  const internal = /^\/(?!\/)/.test(to)
  const theme = useTheme()

  return internal ? (
    <GatsbyLink
      to={to}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      css={getVariantStyles(theme, variant)}
      aria-label={ariaLabel}
      {...rest}
    >
      {children}
    </GatsbyLink>
  ) : (
    <a css={getVariantStyles(theme, variant)} href={to} {...rest}>
      {children}
    </a>
  )
}

export default Link
