import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mediaQueries } from '../../theme'

const sizeStyles = ({ theme: { fontSizes, space }, size = 'medium' }) => {
  switch (size) {
    case 'small':
      return css`
        font-size: ${fontSizes[1]};
        padding: ${space[3]} ${space[6]};
      `
    case 'medium':
      return css`
        font-size: ${fontSizes[2]};
        padding: ${space[4]} ${space[7]};
      `
    case 'large':
      return css`
        font-size: ${fontSizes[4]};
        padding: ${space[5]} ${space[6]};

        ${mediaQueries.lg} {
          font-size: ${fontSizes[5]};
          padding: ${space[7]} ${space[10]};

          line-height: 1.35;
          border-width: 4px;
        }
      `
    default:
      return
  }
}

const variantStyles = ({ theme: { colors }, variant = 'primary' }) => {
  switch (variant) {
    case 'primary':
      return css`
        background-color: ${colors.primary};
        color: ${colors.black[0]};

        &:hover:not([href]),
        &:focus:not([href]) {
          background-color: ${colors.black[0]};
          color: ${colors.white};
        }

        &[href]:hover,
        &[href]:focus {
          text-decoration: underline;
        }
      `
    case 'outline':
      return css`
        background-color: ${colors.white};
        color: ${colors.black[0]};
        border-color: ${colors.black[0]};

        &:hover:not([href]),
        &:focus:not([href]) {
          background-color: ${colors.black[0]};
          color: ${colors.white};
        }

        &[href]:hover,
        &[href]:focus {
          text-decoration: underline;
        }
      `
    case 'social':
      return css`
        background-color: ${colors.black[0]};
        color: ${colors.white};

        &:hover:not([href]),
        &:focus:not([href]) {
          background-color: ${colors.white};
          color: ${colors.black[0]};
          border-color: ${colors.black[0]};
        }

        &[href]:hover,
        &[href]:focus {
          text-decoration: underline;
        }
      `
    default:
      return
  }
}

const Button = styled.button`
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: ${p => p.theme.lineHeights.button};
  text-decoration: none;
  font-size: inherit;
  font-weight: ${p => p.theme.fontWeights.bold};
  border: 2px solid transparent;
  border-radius: ${p => p.theme.space[2]};
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  ${variantStyles}
  ${sizeStyles}
`

export default Button
