import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import useSiteMetadata from '../hooks/use-site-metadata'

// see https://github.com/fontsource/fontsource/issues/83
import montserrat500 from '@fontsource/montserrat/files/montserrat-latin-500-normal.woff2'
import montserrat700 from '@fontsource/montserrat/files/montserrat-latin-700-normal.woff2'

const useDefaultOgImage = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/og-default.png" }) {
        childImageSharp {
          original {
            width
            height
            src
          }
        }
      }
    }
  `)

  return file.childImageSharp.original
}

const SiteMetadata = ({ location }) => {
  const { siteUrl, title, twitter } = useSiteMetadata()
  const image = useDefaultOgImage()
  const locale = 'en'

  // Note: `location.href` isn't available on server-side so we must get it from `pathname`:
  // https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/#article-header-id-4
  const href = `${siteUrl}${location.pathname}`

  return (
    <Helmet defer={false} defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <html lang={locale} />
      <link rel="canonical" href={href} />
      <link rel="preload" href={montserrat500} as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href={montserrat700} as="font" type="font/woff2" crossOrigin="anonymous" />
      <meta name="docsearch:version" content="2.0" />

      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={`${siteUrl}${image.src}`} />
      <meta property="og:image:width" content={image.width} />
      <meta property="og:image:height" content={image.height} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:image" content={`${siteUrl}${image.src}`} />
    </Helmet>
  )
}

export default SiteMetadata
